import React from 'react'

const HausSeriesLink1Svg = () => {
  return (
    <svg
      width="440"
      height="228"
      viewBox="0 0 440 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_331_29295)">
        <path
          d="M157.84 16.52V158.52L394.84 158.52V16.52L157.84 16.52Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M37.8301 152.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.8301 144.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.8301 136.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.8301 128.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 120.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 112.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 104.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 96.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 88.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 80.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 72.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 64.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 56.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 48.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 40.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 32.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.33 24.52H394.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M45.8301 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.8301 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M61.8301 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M77.8301 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M93.8301 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M109.83 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M125.83 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M141.83 129.02V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M157.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M173.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M189.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M205.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M221.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M237.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M253.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M269.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M285.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M301.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M317.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M333.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M349.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M365.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M381.83 16.52V158.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M126.83 281.51V180.51H60.8301V281.51H126.83Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M120.84 242.51H137.84V219.51H120.84V242.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M120.84 219.51H137.84V196.51H120.84V219.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M139.83 196.52H144.83V219.52V242.52V265.52H139.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M144.84 196.51H120.84V192.51C120.84 191.96 121.29 191.51 121.84 191.51H143.84C144.39 191.51 144.84 191.96 144.84 192.51V196.51V196.51Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M137.83 219.52H144.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M91.8301 246.52C100.114 246.52 106.83 239.804 106.83 231.52C106.83 223.236 100.114 216.52 91.8301 216.52C83.5458 216.52 76.8301 223.236 76.8301 231.52C76.8301 239.804 83.5458 246.52 91.8301 246.52Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.8398 257.51H52.8398L52.8398 204.51H43.8398L43.8398 257.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.8398 250.51H48.8398L48.8398 211.51H46.8398L46.8398 250.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.6053 175.311C84.2951 175.344 88.0785 179.18 88.0457 183.87L88.016 188.12C87.9997 190.46 86.0864 192.357 83.7365 192.34L75.2367 192.281C72.8967 192.265 71.0001 190.351 71.0165 188.001L71.0461 183.751C71.0789 179.062 74.9154 175.278 79.6053 175.311Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M79.5909 175.32C83.8409 175.35 87.3309 178.49 87.9409 182.56C88.0509 183.31 88.6609 183.88 89.4209 183.89C90.3509 183.89 91.0509 183.07 90.9109 182.15C90.1009 176.63 85.3609 172.36 79.6109 172.32C73.8609 172.28 69.0609 176.48 68.1709 181.99C68.0209 182.91 68.7109 183.75 69.6409 183.75C70.4009 183.75 71.0109 183.19 71.1409 182.44C71.8009 178.38 75.3409 175.29 79.5909 175.32"
          fill="#B3DFFF"
        />
        <path
          d="M79.5909 175.32C83.8409 175.35 87.3309 178.49 87.9409 182.56C88.0509 183.31 88.6609 183.88 89.4209 183.89C90.3509 183.89 91.0509 183.07 90.9109 182.15C90.1009 176.63 85.3609 172.36 79.6109 172.32C73.8609 172.28 69.0609 176.48 68.1709 181.99C68.0209 182.91 68.7109 183.75 69.6409 183.75C70.4009 183.75 71.0109 183.19 71.1409 182.44C71.8009 178.38 75.3409 175.29 79.5909 175.32"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M105.603 175.492C110.293 175.525 114.077 179.362 114.044 184.052L114.014 188.301C113.998 190.641 112.084 192.538 109.735 192.522L101.235 192.462C98.8948 192.446 96.9981 190.533 97.0145 188.183L97.0442 183.933C97.0769 179.243 100.913 175.46 105.603 175.492Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M105.591 175.5C109.841 175.53 113.331 178.67 113.941 182.74C114.051 183.49 114.661 184.06 115.421 184.07C116.351 184.07 117.051 183.25 116.911 182.33C116.101 176.81 111.361 172.54 105.611 172.5C99.8609 172.46 95.0609 176.66 94.1709 182.17C94.0209 183.09 94.7109 183.93 95.6409 183.93C96.4009 183.93 97.0109 183.37 97.1409 182.62C97.8009 178.56 101.341 175.47 105.591 175.5"
          fill="#B3DFFF"
        />
        <path
          d="M105.591 175.5C109.841 175.53 113.331 178.67 113.941 182.74C114.051 183.49 114.661 184.06 115.421 184.07C116.351 184.07 117.051 183.25 116.911 182.33C116.101 176.81 111.361 172.54 105.611 172.5C99.8609 172.46 95.0609 176.66 94.1709 182.17C94.0209 183.09 94.7109 183.93 95.6409 183.93C96.4009 183.93 97.0109 183.37 97.1409 182.62C97.8009 178.56 101.341 175.47 105.591 175.5"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M233.83 191.23V199.81C233.83 200.76 233.06 201.52 232.12 201.52H229.83C226.52 201.52 223.83 198.83 223.83 195.52C223.83 192.21 226.52 189.52 229.83 189.52H232.12C233.07 189.52 233.83 190.29 233.83 191.23V191.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M222.83 195.52C222.83 192.7 224.5 190.28 226.9 189.17C227.57 188.86 228.33 189.36 228.33 190.09C228.33 190.47 228.12 190.83 227.77 190.99C226.04 191.77 224.83 193.5 224.83 195.52C224.83 197.54 226.04 199.26 227.77 200.05C228.12 200.21 228.33 200.57 228.33 200.95C228.33 201.69 227.56 202.18 226.9 201.87C224.5 200.76 222.83 198.33 222.83 195.52Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M233.83 207.23V215.81C233.83 216.76 233.06 217.52 232.12 217.52H229.83C226.52 217.52 223.83 214.83 223.83 211.52C223.83 208.21 226.52 205.52 229.83 205.52H232.12C233.07 205.52 233.83 206.29 233.83 207.23V207.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M222.83 211.52C222.83 208.7 224.5 206.28 226.9 205.17C227.57 204.86 228.33 205.36 228.33 206.09C228.33 206.47 228.12 206.83 227.77 206.99C226.04 207.77 224.83 209.5 224.83 211.52C224.83 213.54 226.04 215.26 227.77 216.05C228.12 216.21 228.33 216.57 228.33 216.95C228.33 217.69 227.56 218.18 226.9 217.87C224.5 216.76 222.83 214.33 222.83 211.52Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M233.83 223.23V231.81C233.83 232.76 233.06 233.52 232.12 233.52H229.83C226.52 233.52 223.83 230.83 223.83 227.52C223.83 224.21 226.52 221.52 229.83 221.52H232.12C233.07 221.52 233.83 222.29 233.83 223.23V223.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M222.83 227.52C222.83 224.7 224.5 222.28 226.9 221.17C227.57 220.86 228.33 221.36 228.33 222.09C228.33 222.47 228.12 222.83 227.77 222.99C226.04 223.77 224.83 225.5 224.83 227.52C224.83 229.54 226.04 231.26 227.77 232.05C228.12 232.21 228.33 232.57 228.33 232.95C228.33 233.69 227.56 234.18 226.9 233.87C224.5 232.76 222.83 230.33 222.83 227.52Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M230.84 184.52V289.52H256.84V184.52H230.84Z"
          fill="#B3DFFF"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M348.84 161.52V300.52H364.84V161.52H348.84Z"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.8301 161.51V158.51H48.8301V161.51H74.8301Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M100.83 161.51V158.51H74.8301V161.51H100.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M126.83 161.51V158.51H100.83V161.51H126.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M152.83 161.51V158.51H126.83V161.51H152.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M204.83 161.51V158.51H178.83V161.51H204.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M230.83 161.51V158.51H204.83V161.51H230.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M256.83 161.51V158.51H230.83V161.51H256.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M282.83 161.51V158.51H256.83V161.51H282.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M315.83 161.52H313.83V184.52H315.83V161.52Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M316.689 184.21C316.689 184.21 316.769 184.21 316.809 184.21"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M317.82 184.13C328.49 183.13 336.83 174.15 336.83 163.22V163.14"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M336.83 162.64V162.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M287.84 161.52V269.52H307.84V161.52H287.84Z"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M300.83 224.52C301.382 224.52 301.83 224.072 301.83 223.52C301.83 222.968 301.382 222.52 300.83 222.52C300.278 222.52 299.83 222.968 299.83 223.52C299.83 224.072 300.278 224.52 300.83 224.52Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M292.83 225.52C293.935 225.52 294.83 224.625 294.83 223.52C294.83 222.415 293.935 221.52 292.83 221.52C291.726 221.52 290.83 222.415 290.83 223.52C290.83 224.625 291.726 225.52 292.83 225.52Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M292.83 224.52C293.382 224.52 293.83 224.072 293.83 223.52C293.83 222.968 293.382 222.52 292.83 222.52C292.278 222.52 291.83 222.968 291.83 223.52C291.83 224.072 292.278 224.52 292.83 224.52Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M301.83 223.52H304.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M288.83 223.52H291.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M294.83 223.52H299.83"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M300.83 219.52V222.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M292.83 219.52V222.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M292.83 224.52V230.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M300.83 224.52V230.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M296.83 231.52V219.52"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M288.84 219.8V227.24C288.84 227.395 288.965 227.52 289.12 227.52H304.56C304.714 227.52 304.84 227.395 304.84 227.24V219.8C304.84 219.645 304.714 219.52 304.56 219.52H289.12C288.965 219.52 288.84 219.645 288.84 219.8Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M287.84 218.52V245.52H305.84V218.52H287.84Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M286.84 225.52V226.52H287.84V225.52H286.84Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M178.83 158.52H152.83V161.52H178.83V158.52Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M40.8101 158.52H37.8301V161.47V300.56V303.52H40.8101H48.8301V300.52H40.8301V161.52H48.8301V158.52H40.8101Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M373.83 19.52H394.83V158.52H367.57H364.63H336.83V161.52H364.83V251.52H367.83V161.52H394.92H397.83V160.6V19.52V16.52H394.9H373.83V19.52Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M313.83 161.52V158.52H311.17H310.83H308.2H282.83V161.52H307.83V269.52H290.75H287.83V272.44V274.52H290.83V272.52H307.85H310.83V269.49V161.52H313.83Z"
          fill="#B3DFFF"
          stroke="#3957A5"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M405.301 194.16C405.853 194.16 406.301 193.712 406.301 193.16C406.301 192.608 405.853 192.16 405.301 192.16C404.748 192.16 404.301 192.608 404.301 193.16C404.301 193.712 404.748 194.16 405.301 194.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M405.301 192.16C405.853 192.16 406.301 191.712 406.301 191.16C406.301 190.608 405.853 190.16 405.301 190.16C404.748 190.16 404.301 190.608 404.301 191.16C404.301 191.712 404.748 192.16 405.301 192.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M407.301 193.16C407.853 193.16 408.301 192.712 408.301 192.16C408.301 191.608 407.853 191.16 407.301 191.16C406.748 191.16 406.301 191.608 406.301 192.16C406.301 192.712 406.748 193.16 407.301 193.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M407.301 189.16C407.853 189.16 408.301 188.712 408.301 188.16C408.301 187.608 407.853 187.16 407.301 187.16C406.748 187.16 406.301 187.608 406.301 188.16C406.301 188.712 406.748 189.16 407.301 189.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M427.301 191.16C427.853 191.16 428.301 190.712 428.301 190.16C428.301 189.608 427.853 189.16 427.301 189.16C426.748 189.16 426.301 189.608 426.301 190.16C426.301 190.712 426.748 191.16 427.301 191.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M392.262 184.48C394.632 187.72 402.152 191.53 404.532 191.85C404.532 191.85 402.092 189.05 398.642 186.94C395.192 184.83 392.262 184.49 392.262 184.49V184.48Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M395.231 192.45C397.201 194.17 402.181 193.97 403.581 193.13C403.581 193.13 401.661 191.83 399.301 191.6C396.941 191.37 395.221 192.45 395.221 192.45H395.231Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M406.961 184.08C407.241 186.68 411.041 189.91 412.641 190.25C412.641 190.25 412.111 187.99 410.531 186.23C408.951 184.47 406.961 184.09 406.961 184.09V184.08Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M403.171 180.23C402.621 182.73 404.641 187.45 405.801 188.51C405.801 188.51 406.061 186.2 405.341 183.85C404.621 181.5 403.171 180.23 403.171 180.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M415.76 178.17C414.35 180.97 415.28 187.21 416.32 188.82C416.32 188.82 417.33 186.16 417.2 183.15C417.07 180.14 415.76 178.18 415.76 178.18V178.17Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M394.621 179.47C396.161 183.18 402.551 188.67 404.791 189.54C404.791 189.54 403.091 186.24 400.241 183.37C397.391 180.5 394.631 179.47 394.631 179.47H394.621Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M422.301 190.16C422.853 190.16 423.301 189.712 423.301 189.16C423.301 188.608 422.853 188.16 422.301 188.16C421.748 188.16 421.301 188.608 421.301 189.16C421.301 189.712 421.748 190.16 422.301 190.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M425.301 189.16C425.853 189.16 426.301 188.712 426.301 188.16C426.301 187.608 425.853 187.16 425.301 187.16C424.748 187.16 424.301 187.608 424.301 188.16C424.301 188.712 424.748 189.16 425.301 189.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M432.771 175.31C429.241 177.24 424.491 184.19 423.861 186.51C423.861 186.51 426.961 184.46 429.501 181.31C432.041 178.16 432.771 175.31 432.771 175.31V175.31Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M425.811 175.64C423.371 178 421.381 184.74 421.531 186.77C421.531 186.77 423.571 184.41 424.801 181.27C426.031 178.13 425.801 175.64 425.801 175.64H425.811Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M435.321 187.15C433.101 186.03 428.221 186.89 426.971 187.77C426.971 187.77 429.081 188.56 431.441 188.41C433.801 188.26 435.321 187.14 435.321 187.14V187.15Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M435.041 180.23C431.801 180.66 426.801 185.02 425.941 186.82C425.941 186.82 428.841 186.14 431.431 184.3C434.021 182.46 435.041 180.23 435.041 180.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M423.301 195.16C423.853 195.16 424.301 194.712 424.301 194.16C424.301 193.608 423.853 193.16 423.301 193.16C422.748 193.16 422.301 193.608 422.301 194.16C422.301 194.712 422.748 195.16 423.301 195.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M425.301 196.16C425.853 196.16 426.301 195.712 426.301 195.16C426.301 194.608 425.853 194.16 425.301 194.16C424.748 194.16 424.301 194.608 424.301 195.16C424.301 195.712 424.748 196.16 425.301 196.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M438.96 188.88C434.95 188.67 427.25 192.1 425.5 193.74C425.5 193.74 429.21 193.62 433.03 192.28C436.85 190.94 438.96 188.88 438.96 188.88Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M434.92 200.3C433.62 198.18 429.01 196.35 427.48 196.45C427.48 196.45 428.86 198.23 430.95 199.34C433.04 200.45 434.92 200.3 434.92 200.3V200.3Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M439.222 194.24C435.992 192.91 428.912 194.01 427.102 195.1C427.102 195.1 430.162 196.03 433.582 195.82C437.002 195.61 439.212 194.24 439.212 194.24H439.222Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M416.301 203.16C416.853 203.16 417.301 202.712 417.301 202.16C417.301 201.608 416.853 201.16 416.301 201.16C415.748 201.16 415.301 201.608 415.301 202.16C415.301 202.712 415.748 203.16 416.301 203.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M411.9 217.29C413.29 215.22 413.03 210.27 412.31 208.92C412.31 208.92 411.27 210.91 411.12 213.28C410.97 215.65 411.9 217.29 411.9 217.29Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M419.39 217.63C420.36 213.73 418.46 205.52 417.18 203.49C417.18 203.49 416.59 207.16 417.18 211.16C417.77 215.16 419.39 217.63 419.39 217.63Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M394.5 200.51C397.84 198.28 406.21 197.28 408.55 197.8C408.55 197.8 405.3 199.6 401.33 200.4C397.36 201.2 394.5 200.52 394.5 200.52V200.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M390.631 195.98C394.321 194.39 402.731 194.91 404.941 195.84C404.941 195.84 401.421 197.02 397.371 197.09C393.321 197.16 390.631 195.98 390.631 195.98Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M394.721 206.59C397.311 203.51 405.061 200.22 407.451 200.06C407.451 200.06 404.831 202.69 401.241 204.57C397.651 206.45 394.711 206.59 394.711 206.59H394.721Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M413.301 204.16C413.853 204.16 414.301 203.712 414.301 203.16C414.301 202.608 413.853 202.16 413.301 202.16C412.748 202.16 412.301 202.608 412.301 203.16C412.301 203.712 412.748 204.16 413.301 204.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M410.301 203.16C410.853 203.16 411.301 202.712 411.301 202.16C411.301 201.608 410.853 201.16 410.301 201.16C409.748 201.16 409.301 201.608 409.301 202.16C409.301 202.712 409.748 203.16 410.301 203.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M407.152 218.24C410.132 215.38 412.202 207.19 411.852 204.72C411.852 204.72 409.392 207.59 408.032 211.4C406.662 215.21 407.152 218.25 407.152 218.25V218.24Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M415.452 214.58C416.862 211.82 415.932 205.67 414.892 204.08C414.892 204.08 413.882 206.71 414.012 209.68C414.142 212.65 415.452 214.59 415.452 214.59V214.58Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M401.4 206.56C403.87 206.9 408.2 204.5 409.1 203.27C409.1 203.27 406.85 203.2 404.66 204.11C402.47 205.02 401.4 206.57 401.4 206.57V206.56Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M401.58 215.95C405.01 213.86 409.44 206.69 409.95 204.35C409.95 204.35 406.95 206.54 404.56 209.81C402.16 213.07 401.58 215.96 401.58 215.96V215.95Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M423.301 200.16C423.853 200.16 424.301 199.712 424.301 199.16C424.301 198.608 423.853 198.16 423.301 198.16C422.748 198.16 422.301 198.608 422.301 199.16C422.301 199.712 422.748 200.16 423.301 200.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M422.301 202.16C422.853 202.16 423.301 201.712 423.301 201.16C423.301 200.608 422.853 200.16 422.301 200.16C421.748 200.16 421.301 200.608 421.301 201.16C421.301 201.712 421.748 202.16 422.301 202.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M421.301 200.16C421.853 200.16 422.301 199.712 422.301 199.16C422.301 198.608 421.853 198.16 421.301 198.16C420.748 198.16 420.301 198.608 420.301 199.16C420.301 199.712 420.748 200.16 421.301 200.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M432.77 208.39C431.59 205.33 426.18 201.48 424.23 201.06C424.23 201.06 425.58 203.73 427.97 205.82C430.36 207.92 432.77 208.39 432.77 208.39V208.39Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M436.531 204.22C434.361 201.56 427.711 199.28 425.641 199.4C425.641 199.4 427.851 201.63 430.921 203.02C433.991 204.41 436.531 204.22 436.531 204.22Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M420.511 209.07C421.191 207 419.741 202.93 418.791 201.98C418.791 201.98 418.381 203.9 418.851 205.91C419.311 207.92 420.511 209.06 420.511 209.06V209.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M424.19 214.78C424.98 211.62 422.76 205.41 421.41 203.97C421.41 203.97 420.98 206.9 421.74 209.97C422.49 213.03 424.19 214.78 424.19 214.78Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M408.57 201.55C410.87 200.81 414.07 197.05 414.52 195.66C414.52 195.66 412.49 196.54 410.8 198.19C409.1 199.84 408.57 201.56 408.57 201.56V201.55Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M414.492 202.17C415.822 200.65 415.612 196.83 414.942 195.76C414.942 195.76 413.942 197.24 413.782 199.05C413.622 200.86 414.482 202.17 414.482 202.17H414.492Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M420.091 201.73C420.201 199.26 417.011 195.91 415.521 195.47C415.521 195.47 415.691 197.64 416.961 199.43C418.231 201.22 420.102 201.73 420.102 201.73H420.091Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M424.141 197.07C422.381 195.35 417.501 194.53 416.051 194.9C416.051 194.9 417.801 196.28 420.081 196.92C422.361 197.56 424.141 197.07 424.141 197.07V197.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M425.301 190.17C422.281 189.87 417.091 192.98 416.051 194.52C416.051 194.52 418.791 194.49 421.421 193.3C424.051 192.11 425.301 190.18 425.301 190.18V190.17Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M420.752 184.51C418.322 186.1 416.082 191.53 416.122 193.3C416.122 193.3 418.172 191.63 419.512 189.16C420.852 186.69 420.762 184.51 420.762 184.51H420.752Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M413.121 185.63C412.201 188.08 414.131 192.67 415.411 193.69C415.411 193.69 415.961 191.44 415.341 189.15C414.721 186.86 413.121 185.63 413.121 185.63Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M407.73 189.59C408.6 192.05 413.03 194.33 414.67 194.29C414.67 194.29 413.64 192.21 411.7 190.85C409.76 189.49 407.73 189.58 407.73 189.58V189.59Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M406.051 195.77C408.291 196.89 413.161 195.92 414.391 195C414.391 195 412.271 194.22 409.911 194.41C407.551 194.6 406.051 195.77 406.051 195.77V195.77Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M429.901 213.74C429.521 210.16 425.121 204.18 423.281 203.02C423.281 203.02 423.931 206.26 425.771 209.31C427.611 212.36 429.891 213.74 429.891 213.74H429.901Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M410.301 184.16C410.853 184.16 411.301 183.712 411.301 183.16C411.301 182.608 410.853 182.16 410.301 182.16C409.748 182.16 409.301 182.608 409.301 183.16C409.301 183.712 409.748 184.16 410.301 184.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M412.301 183.16C412.853 183.16 413.301 182.712 413.301 182.16C413.301 181.608 412.853 181.16 412.301 181.16C411.748 181.16 411.301 181.608 411.301 182.16C411.301 182.712 411.748 183.16 412.301 183.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M412.301 185.16C412.853 185.16 413.301 184.712 413.301 184.16C413.301 183.608 412.853 183.16 412.301 183.16C411.748 183.16 411.301 183.608 411.301 184.16C411.301 184.712 411.748 185.16 412.301 185.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M410.741 170.07C409.171 173.04 409.741 179.87 410.681 181.68C410.681 181.68 411.841 178.84 411.891 175.56C411.941 172.28 410.741 170.07 410.741 170.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M406.161 173.54C405.531 176.07 407.971 180.42 409.351 181.29C409.351 181.29 409.641 178.99 408.771 176.78C407.901 174.57 406.161 173.53 406.161 173.53V173.54Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M401.611 175.3C402.311 178.13 406.561 182.34 408.181 183.02C408.181 183.02 407.291 180.5 405.461 178.3C403.631 176.1 401.611 175.3 401.611 175.3V175.3Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M416.231 169.51C413.891 171.93 412.531 178.66 412.931 180.66C412.931 180.66 414.841 178.25 415.811 175.11C416.781 171.97 416.241 169.51 416.241 169.51H416.231Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M417.991 171.13C416.721 174.67 417.971 182.37 419.091 184.33C419.091 184.33 419.961 180.98 419.681 177.25C419.401 173.51 417.991 171.13 417.991 171.13V171.13Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M422.91 171.12C420.85 173.59 420.21 180.05 420.82 181.91C420.82 181.91 422.46 179.49 423.08 176.44C423.7 173.39 422.91 171.12 422.91 171.12Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.3008 49.16C15.8531 49.16 16.3008 48.7123 16.3008 48.16C16.3008 47.6077 15.8531 47.16 15.3008 47.16C14.7485 47.16 14.3008 47.6077 14.3008 48.16C14.3008 48.7123 14.7485 49.16 15.3008 49.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.3008 47.16C15.8531 47.16 16.3008 46.7123 16.3008 46.16C16.3008 45.6077 15.8531 45.16 15.3008 45.16C14.7485 45.16 14.3008 45.6077 14.3008 46.16C14.3008 46.7123 14.7485 47.16 15.3008 47.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.3008 48.16C17.8531 48.16 18.3008 47.7123 18.3008 47.16C18.3008 46.6077 17.8531 46.16 17.3008 46.16C16.7485 46.16 16.3008 46.6077 16.3008 47.16C16.3008 47.7123 16.7485 48.16 17.3008 48.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.3008 44.16C17.8531 44.16 18.3008 43.7123 18.3008 43.16C18.3008 42.6077 17.8531 42.16 17.3008 42.16C16.7485 42.16 16.3008 42.6077 16.3008 43.16C16.3008 43.7123 16.7485 44.16 17.3008 44.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.3008 46.16C37.8531 46.16 38.3008 45.7123 38.3008 45.16C38.3008 44.6077 37.8531 44.16 37.3008 44.16C36.7485 44.16 36.3008 44.6077 36.3008 45.16C36.3008 45.7123 36.7485 46.16 37.3008 46.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M2.26172 39.48C4.63172 42.72 12.1517 46.53 14.5317 46.85C14.5317 46.85 12.0917 44.05 8.64172 41.94C5.19172 39.83 2.26172 39.49 2.26172 39.49V39.48Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M5.2307 47.45C7.2007 49.17 12.1807 48.97 13.5807 48.13C13.5807 48.13 11.6607 46.83 9.3007 46.6C6.9407 46.37 5.2207 47.45 5.2207 47.45H5.2307Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.9609 39.08C17.2409 41.68 21.0409 44.91 22.6409 45.25C22.6409 45.25 22.1109 42.99 20.5309 41.23C18.9509 39.47 16.9609 39.09 16.9609 39.09V39.08Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M13.1711 35.23C12.6211 37.73 14.6411 42.45 15.8011 43.51C15.8011 43.51 16.0611 41.2 15.3411 38.85C14.6211 36.5 13.1711 35.23 13.1711 35.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M25.7601 33.17C24.3501 35.97 25.2801 42.21 26.3201 43.82C26.3201 43.82 27.3301 41.16 27.2001 38.15C27.0701 35.14 25.7601 33.18 25.7601 33.18V33.17Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M4.62109 34.47C6.16109 38.18 12.5511 43.67 14.7911 44.54C14.7911 44.54 13.0911 41.24 10.2411 38.37C7.39109 35.5 4.63109 34.47 4.63109 34.47H4.62109Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M32.3008 45.16C32.8531 45.16 33.3008 44.7123 33.3008 44.16C33.3008 43.6077 32.8531 43.16 32.3008 43.16C31.7485 43.16 31.3008 43.6077 31.3008 44.16C31.3008 44.7123 31.7485 45.16 32.3008 45.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.3008 44.16C35.8531 44.16 36.3008 43.7123 36.3008 43.16C36.3008 42.6077 35.8531 42.16 35.3008 42.16C34.7485 42.16 34.3008 42.6077 34.3008 43.16C34.3008 43.7123 34.7485 44.16 35.3008 44.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M42.7713 30.31C39.2413 32.24 34.4913 39.19 33.8613 41.51C33.8613 41.51 36.9613 39.46 39.5013 36.31C42.0413 33.16 42.7713 30.31 42.7713 30.31V30.31Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.8114 30.64C33.3714 33 31.3814 39.74 31.5314 41.77C31.5314 41.77 33.5714 39.41 34.8014 36.27C36.0314 33.13 35.8014 30.64 35.8014 30.64H35.8114Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M45.3207 42.15C43.1007 41.03 38.2207 41.89 36.9707 42.77C36.9707 42.77 39.0807 43.56 41.4407 43.41C43.8007 43.26 45.3207 42.14 45.3207 42.14V42.15Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M45.0414 35.23C41.8014 35.66 36.8014 40.02 35.9414 41.82C35.9414 41.82 38.8414 41.14 41.4314 39.3C44.0214 37.46 45.0414 35.23 45.0414 35.23V35.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M33.3008 50.16C33.8531 50.16 34.3008 49.7123 34.3008 49.16C34.3008 48.6077 33.8531 48.16 33.3008 48.16C32.7485 48.16 32.3008 48.6077 32.3008 49.16C32.3008 49.7123 32.7485 50.16 33.3008 50.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.3008 51.16C35.8531 51.16 36.3008 50.7123 36.3008 50.16C36.3008 49.6077 35.8531 49.16 35.3008 49.16C34.7485 49.16 34.3008 49.6077 34.3008 50.16C34.3008 50.7123 34.7485 51.16 35.3008 51.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M48.96 43.88C44.95 43.67 37.25 47.1 35.5 48.74C35.5 48.74 39.21 48.62 43.03 47.28C46.85 45.94 48.96 43.88 48.96 43.88Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M44.9205 55.3C43.6205 53.18 39.0105 51.35 37.4805 51.45C37.4805 51.45 38.8605 53.23 40.9505 54.34C43.0405 55.45 44.9205 55.3 44.9205 55.3Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M49.2216 49.24C45.9916 47.91 38.9116 49.01 37.1016 50.1C37.1016 50.1 40.1616 51.03 43.5816 50.82C47.0016 50.61 49.2116 49.24 49.2116 49.24H49.2216Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M26.3008 58.16C26.8531 58.16 27.3008 57.7123 27.3008 57.16C27.3008 56.6077 26.8531 56.16 26.3008 56.16C25.7485 56.16 25.3008 56.6077 25.3008 57.16C25.3008 57.7123 25.7485 58.16 26.3008 58.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M21.8999 72.29C23.2899 70.22 23.0299 65.27 22.3099 63.92C22.3099 63.92 21.2699 65.91 21.1199 68.28C20.9699 70.65 21.8999 72.29 21.8999 72.29V72.29Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M29.3902 72.63C30.3602 68.73 28.4602 60.52 27.1802 58.49C27.1802 58.49 26.5902 62.16 27.1802 66.16C27.7702 70.16 29.3902 72.63 29.3902 72.63Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M4.5 55.51C7.84 53.28 16.21 52.28 18.55 52.8C18.55 52.8 15.3 54.6 11.33 55.4C7.36 56.2 4.5 55.52 4.5 55.52V55.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M0.630859 50.98C4.32086 49.39 12.7309 49.91 14.9409 50.84C14.9409 50.84 11.4209 52.02 7.37086 52.09C3.32086 52.16 0.630859 50.98 0.630859 50.98V50.98Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M4.72094 61.59C7.31094 58.51 15.0609 55.22 17.4509 55.06C17.4509 55.06 14.8309 57.69 11.2409 59.57C7.65094 61.45 4.71094 61.59 4.71094 61.59H4.72094Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M23.3008 59.16C23.8531 59.16 24.3008 58.7123 24.3008 58.16C24.3008 57.6077 23.8531 57.16 23.3008 57.16C22.7485 57.16 22.3008 57.6077 22.3008 58.16C22.3008 58.7123 22.7485 59.16 23.3008 59.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.3008 58.16C20.8531 58.16 21.3008 57.7123 21.3008 57.16C21.3008 56.6077 20.8531 56.16 20.3008 56.16C19.7485 56.16 19.3008 56.6077 19.3008 57.16C19.3008 57.7123 19.7485 58.16 20.3008 58.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.1516 73.24C20.1316 70.38 22.2016 62.19 21.8516 59.72C21.8516 59.72 19.3916 62.59 18.0316 66.4C16.6616 70.21 17.1516 73.25 17.1516 73.25V73.24Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M25.4516 69.58C26.8616 66.82 25.9316 60.67 24.8916 59.08C24.8916 59.08 23.8816 61.71 24.0116 64.68C24.1416 67.65 25.4516 69.59 25.4516 69.59V69.58Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M11.4004 61.56C13.8704 61.9 18.2004 59.5 19.1004 58.27C19.1004 58.27 16.8504 58.2 14.6604 59.11C12.4704 60.02 11.4004 61.57 11.4004 61.57V61.56Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M11.5801 70.95C15.0101 68.86 19.4401 61.69 19.9501 59.35C19.9501 59.35 16.9501 61.54 14.5601 64.81C12.1601 68.07 11.5801 70.96 11.5801 70.96V70.95Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M33.3008 55.16C33.8531 55.16 34.3008 54.7123 34.3008 54.16C34.3008 53.6077 33.8531 53.16 33.3008 53.16C32.7485 53.16 32.3008 53.6077 32.3008 54.16C32.3008 54.7123 32.7485 55.16 33.3008 55.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M32.3008 57.16C32.8531 57.16 33.3008 56.7123 33.3008 56.16C33.3008 55.6077 32.8531 55.16 32.3008 55.16C31.7485 55.16 31.3008 55.6077 31.3008 56.16C31.3008 56.7123 31.7485 57.16 32.3008 57.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M31.3008 55.16C31.8531 55.16 32.3008 54.7123 32.3008 54.16C32.3008 53.6077 31.8531 53.16 31.3008 53.16C30.7485 53.16 30.3008 53.6077 30.3008 54.16C30.3008 54.7123 30.7485 55.16 31.3008 55.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M42.7705 63.39C41.5905 60.33 36.1805 56.48 34.2305 56.06C34.2305 56.06 35.5805 58.73 37.9705 60.82C40.3605 62.92 42.7705 63.39 42.7705 63.39Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M46.5306 59.22C44.3606 56.56 37.7106 54.28 35.6406 54.4C35.6406 54.4 37.8506 56.63 40.9206 58.02C43.9906 59.41 46.5306 59.22 46.5306 59.22Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30.5107 64.07C31.1907 62 29.7407 57.93 28.7907 56.98C28.7907 56.98 28.3807 58.9 28.8507 60.91C29.3107 62.92 30.5107 64.06 30.5107 64.06V64.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M34.1902 69.78C34.9802 66.62 32.7602 60.41 31.4102 58.97C31.4102 58.97 30.9802 61.9 31.7402 64.97C32.4902 68.03 34.1902 69.78 34.1902 69.78Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.5703 56.55C20.8703 55.81 24.0703 52.05 24.5203 50.66C24.5203 50.66 22.4903 51.54 20.8003 53.19C19.1003 54.84 18.5703 56.56 18.5703 56.56V56.55Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M24.4916 57.17C25.8216 55.65 25.6116 51.83 24.9416 50.76C24.9416 50.76 23.9416 52.24 23.7816 54.05C23.6216 55.86 24.4816 57.17 24.4816 57.17H24.4916Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30.0915 56.73C30.2015 54.26 27.0115 50.91 25.5215 50.47C25.5215 50.47 25.6915 52.64 26.9615 54.43C28.2315 56.22 30.1015 56.73 30.1015 56.73H30.0915Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M34.1408 52.07C32.3808 50.35 27.5008 49.53 26.0508 49.9C26.0508 49.9 27.8008 51.28 30.0808 51.92C32.3608 52.56 34.1408 52.07 34.1408 52.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.3008 45.17C32.2808 44.87 27.0908 47.98 26.0508 49.52C26.0508 49.52 28.7908 49.49 31.4208 48.3C34.0508 47.11 35.3008 45.18 35.3008 45.18V45.17Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30.7516 39.51C28.3216 41.1 26.0816 46.53 26.1216 48.3C26.1216 48.3 28.1716 46.63 29.5116 44.16C30.8516 41.69 30.7616 39.51 30.7616 39.51H30.7516Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M23.1211 40.63C22.2011 43.08 24.1311 47.67 25.4111 48.69C25.4111 48.69 25.9611 46.44 25.3411 44.15C24.7211 41.86 23.1211 40.63 23.1211 40.63Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.7305 44.59C18.6005 47.05 23.0305 49.33 24.6705 49.29C24.6705 49.29 23.6405 47.21 21.7005 45.85C19.7605 44.49 17.7305 44.58 17.7305 44.58V44.59Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.0508 50.77C18.2908 51.89 23.1608 50.92 24.3908 50C24.3908 50 22.2708 49.22 19.9108 49.41C17.5508 49.6 16.0508 50.77 16.0508 50.77V50.77Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M39.9013 68.74C39.5213 65.16 35.1213 59.18 33.2812 58.02C33.2812 58.02 33.9313 61.26 35.7713 64.31C37.6113 67.36 39.8913 68.74 39.8913 68.74H39.9013Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.3008 39.16C20.8531 39.16 21.3008 38.7123 21.3008 38.16C21.3008 37.6077 20.8531 37.16 20.3008 37.16C19.7485 37.16 19.3008 37.6077 19.3008 38.16C19.3008 38.7123 19.7485 39.16 20.3008 39.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M22.3008 38.16C22.8531 38.16 23.3008 37.7123 23.3008 37.16C23.3008 36.6077 22.8531 36.16 22.3008 36.16C21.7485 36.16 21.3008 36.6077 21.3008 37.16C21.3008 37.7123 21.7485 38.16 22.3008 38.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M22.3008 40.16C22.8531 40.16 23.3008 39.7123 23.3008 39.16C23.3008 38.6077 22.8531 38.16 22.3008 38.16C21.7485 38.16 21.3008 38.6077 21.3008 39.16C21.3008 39.7123 21.7485 40.16 22.3008 40.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.7405 25.07C19.1705 28.04 19.7405 34.87 20.6805 36.68C20.6805 36.68 21.8405 33.84 21.8905 30.56C21.9405 27.28 20.7405 25.07 20.7405 25.07V25.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.1606 28.54C15.5306 31.07 17.9706 35.42 19.3506 36.29C19.3506 36.29 19.6406 33.99 18.7706 31.78C17.9006 29.57 16.1606 28.53 16.1606 28.53V28.54Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M11.6113 30.3C12.3113 33.13 16.5613 37.34 18.1813 38.02C18.1813 38.02 17.2913 35.5 15.4613 33.3C13.6313 31.1 11.6113 30.3 11.6113 30.3V30.3Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M26.2307 24.51C23.8907 26.93 22.5307 33.66 22.9307 35.66C22.9307 35.66 24.8407 33.25 25.8107 30.11C26.7807 26.97 26.2407 24.51 26.2407 24.51H26.2307Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M27.9912 26.13C26.7212 29.67 27.9712 37.37 29.0912 39.33C29.0912 39.33 29.9612 35.98 29.6812 32.25C29.4012 28.51 27.9912 26.13 27.9912 26.13Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M32.9105 26.12C30.8505 28.59 30.2105 35.05 30.8205 36.91C30.8205 36.91 32.4605 34.49 33.0805 31.44C33.7005 28.39 32.9105 26.12 32.9105 26.12V26.12Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M61.3008 25.16C61.8531 25.16 62.3008 24.7123 62.3008 24.16C62.3008 23.6077 61.8531 23.16 61.3008 23.16C60.7485 23.16 60.3008 23.6077 60.3008 24.16C60.3008 24.7123 60.7485 25.16 61.3008 25.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M61.3008 23.16C61.8531 23.16 62.3008 22.7123 62.3008 22.16C62.3008 21.6077 61.8531 21.16 61.3008 21.16C60.7485 21.16 60.3008 21.6077 60.3008 22.16C60.3008 22.7123 60.7485 23.16 61.3008 23.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M63.3008 24.16C63.8531 24.16 64.3008 23.7123 64.3008 23.16C64.3008 22.6077 63.8531 22.16 63.3008 22.16C62.7485 22.16 62.3008 22.6077 62.3008 23.16C62.3008 23.7123 62.7485 24.16 63.3008 24.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M63.3008 20.16C63.8531 20.16 64.3008 19.7123 64.3008 19.16C64.3008 18.6077 63.8531 18.16 63.3008 18.16C62.7485 18.16 62.3008 18.6077 62.3008 19.16C62.3008 19.7123 62.7485 20.16 63.3008 20.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M83.3008 22.16C83.8531 22.16 84.3008 21.7123 84.3008 21.16C84.3008 20.6077 83.8531 20.16 83.3008 20.16C82.7485 20.16 82.3008 20.6077 82.3008 21.16C82.3008 21.7123 82.7485 22.16 83.3008 22.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M48.2617 15.48C50.6317 18.72 58.1517 22.53 60.5317 22.85C60.5317 22.85 58.0917 20.05 54.6417 17.94C51.1917 15.83 48.2617 15.49 48.2617 15.49V15.48Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M51.2307 23.45C53.2007 25.17 58.1807 24.97 59.5807 24.13C59.5807 24.13 57.6607 22.83 55.3007 22.6C52.9407 22.37 51.2207 23.45 51.2207 23.45H51.2307Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M62.9609 15.08C63.2409 17.68 67.0409 20.91 68.6409 21.25C68.6409 21.25 68.1109 18.99 66.5309 17.23C64.9509 15.47 62.9609 15.09 62.9609 15.09V15.08Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M59.1711 11.23C58.6211 13.73 60.6411 18.45 61.8011 19.51C61.8011 19.51 62.0611 17.2 61.3411 14.85C60.6211 12.5 59.1711 11.23 59.1711 11.23V11.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M71.7601 9.17001C70.3501 11.97 71.2801 18.21 72.3201 19.82C72.3201 19.82 73.3301 17.16 73.2001 14.15C73.0701 11.14 71.7601 9.18001 71.7601 9.18001V9.17001Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.6211 10.47C52.1611 14.18 58.5511 19.67 60.7911 20.54C60.7911 20.54 59.0911 17.24 56.2411 14.37C53.3911 11.5 50.6311 10.47 50.6311 10.47H50.6211Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M78.3008 21.16C78.8531 21.16 79.3008 20.7123 79.3008 20.16C79.3008 19.6077 78.8531 19.16 78.3008 19.16C77.7485 19.16 77.3008 19.6077 77.3008 20.16C77.3008 20.7123 77.7485 21.16 78.3008 21.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M81.3008 20.16C81.8531 20.16 82.3008 19.7123 82.3008 19.16C82.3008 18.6077 81.8531 18.16 81.3008 18.16C80.7485 18.16 80.3008 18.6077 80.3008 19.16C80.3008 19.7123 80.7485 20.16 81.3008 20.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M88.7713 6.31C85.2413 8.24 80.4913 15.19 79.8613 17.51C79.8613 17.51 82.9613 15.46 85.5013 12.31C88.0413 9.16 88.7713 6.31 88.7713 6.31V6.31Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M81.8114 6.64001C79.3714 9.00001 77.3814 15.74 77.5314 17.77C77.5314 17.77 79.5714 15.41 80.8014 12.27C82.0314 9.13002 81.8014 6.64001 81.8014 6.64001H81.8114Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M91.3207 18.15C89.1007 17.03 84.2207 17.89 82.9707 18.77C82.9707 18.77 85.0807 19.56 87.4407 19.41C89.8007 19.26 91.3207 18.14 91.3207 18.14V18.15Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M91.0414 11.23C87.8014 11.66 82.8014 16.02 81.9414 17.82C81.9414 17.82 84.8414 17.14 87.4314 15.3C90.0214 13.46 91.0414 11.23 91.0414 11.23Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M79.3008 26.16C79.8531 26.16 80.3008 25.7123 80.3008 25.16C80.3008 24.6077 79.8531 24.16 79.3008 24.16C78.7485 24.16 78.3008 24.6077 78.3008 25.16C78.3008 25.7123 78.7485 26.16 79.3008 26.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M81.3008 27.16C81.8531 27.16 82.3008 26.7123 82.3008 26.16C82.3008 25.6077 81.8531 25.16 81.3008 25.16C80.7485 25.16 80.3008 25.6077 80.3008 26.16C80.3008 26.7123 80.7485 27.16 81.3008 27.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M94.96 19.88C90.95 19.67 83.25 23.1 81.5 24.74C81.5 24.74 85.21 24.62 89.03 23.28C92.85 21.94 94.96 19.88 94.96 19.88V19.88Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M90.9205 31.3C89.6205 29.18 85.0105 27.35 83.4805 27.45C83.4805 27.45 84.8605 29.23 86.9505 30.34C89.0405 31.45 90.9205 31.3 90.9205 31.3Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M95.2216 25.24C91.9916 23.91 84.9116 25.01 83.1016 26.1C83.1016 26.1 86.1616 27.03 89.5816 26.82C93.0016 26.61 95.2116 25.24 95.2116 25.24H95.2216Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M72.3008 34.16C72.8531 34.16 73.3008 33.7123 73.3008 33.16C73.3008 32.6077 72.8531 32.16 72.3008 32.16C71.7485 32.16 71.3008 32.6077 71.3008 33.16C71.3008 33.7123 71.7485 34.16 72.3008 34.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M67.8999 48.29C69.2899 46.22 69.0299 41.27 68.3099 39.92C68.3099 39.92 67.2699 41.91 67.1199 44.28C66.9699 46.65 67.8999 48.29 67.8999 48.29Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M75.3902 48.63C76.3602 44.73 74.4602 36.52 73.1802 34.49C73.1802 34.49 72.5902 38.16 73.1802 42.16C73.7702 46.16 75.3902 48.63 75.3902 48.63Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.5 31.51C53.84 29.28 62.21 28.28 64.55 28.8C64.55 28.8 61.3 30.6 57.33 31.4C53.36 32.2 50.5 31.52 50.5 31.52V31.51Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M46.6309 26.98C50.3209 25.39 58.7309 25.91 60.9409 26.84C60.9409 26.84 57.4209 28.02 53.3709 28.09C49.3209 28.16 46.6309 26.98 46.6309 26.98Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.7209 37.59C53.3109 34.51 61.0609 31.22 63.4509 31.06C63.4509 31.06 60.8309 33.69 57.2409 35.57C53.6509 37.45 50.7109 37.59 50.7109 37.59H50.7209Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M69.3008 35.16C69.8531 35.16 70.3008 34.7123 70.3008 34.16C70.3008 33.6077 69.8531 33.16 69.3008 33.16C68.7485 33.16 68.3008 33.6077 68.3008 34.16C68.3008 34.7123 68.7485 35.16 69.3008 35.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M66.3008 34.16C66.8531 34.16 67.3008 33.7123 67.3008 33.16C67.3008 32.6077 66.8531 32.16 66.3008 32.16C65.7485 32.16 65.3008 32.6077 65.3008 33.16C65.3008 33.7123 65.7485 34.16 66.3008 34.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M63.1516 49.24C66.1316 46.38 68.2016 38.19 67.8516 35.72C67.8516 35.72 65.3916 38.59 64.0316 42.4C62.6616 46.21 63.1516 49.25 63.1516 49.25V49.24Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M71.4515 45.58C72.8615 42.82 71.9316 36.67 70.8916 35.08C70.8916 35.08 69.8816 37.71 70.0116 40.68C70.1416 43.65 71.4515 45.59 71.4515 45.59V45.58Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M57.4004 37.56C59.8704 37.9 64.2004 35.5 65.1004 34.27C65.1004 34.27 62.8504 34.2 60.6604 35.11C58.4704 36.02 57.4004 37.57 57.4004 37.57V37.56Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M57.5801 46.95C61.0101 44.86 65.4401 37.69 65.9501 35.35C65.9501 35.35 62.9501 37.54 60.5601 40.81C58.1601 44.07 57.5801 46.96 57.5801 46.96V46.95Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M79.3008 31.16C79.8531 31.16 80.3008 30.7123 80.3008 30.16C80.3008 29.6077 79.8531 29.16 79.3008 29.16C78.7485 29.16 78.3008 29.6077 78.3008 30.16C78.3008 30.7123 78.7485 31.16 79.3008 31.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M78.3008 33.16C78.8531 33.16 79.3008 32.7123 79.3008 32.16C79.3008 31.6077 78.8531 31.16 78.3008 31.16C77.7485 31.16 77.3008 31.6077 77.3008 32.16C77.3008 32.7123 77.7485 33.16 78.3008 33.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M77.3008 31.16C77.8531 31.16 78.3008 30.7123 78.3008 30.16C78.3008 29.6077 77.8531 29.16 77.3008 29.16C76.7485 29.16 76.3008 29.6077 76.3008 30.16C76.3008 30.7123 76.7485 31.16 77.3008 31.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M88.7705 39.39C87.5905 36.33 82.1805 32.48 80.2305 32.06C80.2305 32.06 81.5805 34.73 83.9705 36.82C86.3605 38.92 88.7705 39.39 88.7705 39.39V39.39Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M92.5306 35.22C90.3606 32.56 83.7106 30.28 81.6406 30.4C81.6406 30.4 83.8506 32.63 86.9206 34.02C89.9906 35.41 92.5306 35.22 92.5306 35.22Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M76.5107 40.07C77.1907 38 75.7407 33.93 74.7907 32.98C74.7907 32.98 74.3807 34.9 74.8507 36.91C75.3107 38.92 76.5107 40.06 76.5107 40.06V40.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M80.1902 45.78C80.9802 42.62 78.7602 36.41 77.4102 34.97C77.4102 34.97 76.9802 37.9 77.7402 40.97C78.4902 44.03 80.1902 45.78 80.1902 45.78Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M64.5703 32.55C66.8703 31.81 70.0703 28.05 70.5203 26.66C70.5203 26.66 68.4903 27.54 66.8003 29.19C65.1003 30.84 64.5703 32.56 64.5703 32.56V32.55Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M70.4916 33.17C71.8216 31.65 71.6116 27.83 70.9416 26.76C70.9416 26.76 69.9416 28.24 69.7816 30.05C69.6216 31.86 70.4816 33.17 70.4816 33.17H70.4916Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M76.0915 32.73C76.2015 30.26 73.0115 26.91 71.5215 26.47C71.5215 26.47 71.6915 28.64 72.9615 30.43C74.2315 32.22 76.1015 32.73 76.1015 32.73H76.0915Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M80.1408 28.07C78.3808 26.35 73.5008 25.53 72.0508 25.9C72.0508 25.9 73.8008 27.28 76.0808 27.92C78.3608 28.56 80.1408 28.07 80.1408 28.07V28.07Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M81.3008 21.17C78.2808 20.87 73.0908 23.98 72.0508 25.52C72.0508 25.52 74.7908 25.49 77.4208 24.3C80.0508 23.11 81.3008 21.18 81.3008 21.18V21.17Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M76.7516 15.51C74.3216 17.1 72.0816 22.53 72.1216 24.3C72.1216 24.3 74.1716 22.63 75.5116 20.16C76.8516 17.69 76.7616 15.51 76.7616 15.51H76.7516Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M69.1211 16.63C68.2011 19.08 70.1311 23.67 71.4111 24.69C71.4111 24.69 71.9611 22.44 71.3411 20.15C70.7211 17.86 69.1211 16.63 69.1211 16.63V16.63Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M63.7305 20.59C64.6005 23.05 69.0305 25.33 70.6705 25.29C70.6705 25.29 69.6405 23.21 67.7005 21.85C65.7605 20.49 63.7305 20.58 63.7305 20.58V20.59Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M62.0508 26.77C64.2908 27.89 69.1608 26.92 70.3908 26C70.3908 26 68.2708 25.22 65.9108 25.41C63.5508 25.6 62.0508 26.77 62.0508 26.77Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M85.9013 44.74C85.5213 41.16 81.1213 35.18 79.2812 34.02C79.2812 34.02 79.9312 37.26 81.7712 40.31C83.6112 43.36 85.8913 44.74 85.8913 44.74H85.9013Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M66.3008 15.16C66.8531 15.16 67.3008 14.7123 67.3008 14.16C67.3008 13.6077 66.8531 13.16 66.3008 13.16C65.7485 13.16 65.3008 13.6077 65.3008 14.16C65.3008 14.7123 65.7485 15.16 66.3008 15.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M68.3008 14.16C68.8531 14.16 69.3008 13.7123 69.3008 13.16C69.3008 12.6077 68.8531 12.16 68.3008 12.16C67.7485 12.16 67.3008 12.6077 67.3008 13.16C67.3008 13.7123 67.7485 14.16 68.3008 14.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M68.3008 16.16C68.8531 16.16 69.3008 15.7123 69.3008 15.16C69.3008 14.6077 68.8531 14.16 68.3008 14.16C67.7485 14.16 67.3008 14.6077 67.3008 15.16C67.3008 15.7123 67.7485 16.16 68.3008 16.16Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M66.7405 1.07001C65.1705 4.04001 65.7405 10.87 66.6805 12.68C66.6805 12.68 67.8405 9.84001 67.8905 6.56001C67.9405 3.28001 66.7405 1.07001 66.7405 1.07001V1.07001Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M62.1606 4.54C61.5306 7.07 63.9706 11.42 65.3506 12.29C65.3506 12.29 65.6406 9.99 64.7706 7.78C63.9006 5.57 62.1606 4.53 62.1606 4.53V4.54Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M57.6113 6.30002C58.3113 9.13002 62.5613 13.34 64.1813 14.02C64.1813 14.02 63.2913 11.5 61.4613 9.30002C59.6313 7.10002 57.6113 6.30002 57.6113 6.30002V6.30002Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M72.2307 0.51001C69.8907 2.93001 68.5307 9.66001 68.9307 11.66C68.9307 11.66 70.8407 9.25001 71.8107 6.11001C72.7807 2.97001 72.2407 0.51001 72.2407 0.51001H72.2307Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M73.9912 2.13C72.7212 5.67 73.9712 13.37 75.0912 15.33C75.0912 15.33 75.9612 11.98 75.6812 8.25C75.4012 4.51 73.9912 2.13 73.9912 2.13V2.13Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M78.9105 2.12C76.8505 4.59 76.2105 11.05 76.8205 12.91C76.8205 12.91 78.4605 10.49 79.0805 7.44C79.7005 4.39 78.9105 2.12 78.9105 2.12Z"
          stroke="#7AA7CB"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_29295">
          <rect width="440" height="228" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HausSeriesLink1Svg
